import React, { useState, useEffect } from 'react';
import './header.css';
import logotipo from '../../assets/logo.svg';
import { Link } from 'react-router-dom';

export default function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (menuOpen && !event.target.closest('nav') && !event.target.closest('.menu-icon')) {
                closeMenu();
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [menuOpen]);

    return (
        <header>
            <div className={`menu-icon ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
                &#9776;
            </div>
            <div className='logo'>
                <Link to={'/'}>
                <img alt='Logotipo escrito Alef Daniel' src={logotipo} />
                </Link>
                
            </div>
            <div className={`overlay ${menuOpen ? 'open' : ''}`} onClick={closeMenu}></div>
            <nav className={menuOpen ? 'open' : ''}>
                <ul>
                    <Link to={'/'} onClick={closeMenu}><li>Inicio</li></Link>
                    <Link to={'/about'} onClick={closeMenu}><li>Sobre</li></Link>
                    <Link to={'/projects'} onClick={closeMenu}><li>Projetos</li></Link>
                    <Link to={'/contact'} onClick={closeMenu}><li>Contato</li></Link>
                </ul>
            </nav>
        </header>
    );
}
