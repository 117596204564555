import { Link } from "react-router-dom";
import { CiLink } from "react-icons/ci";
import  "./card.css"
import { Bold } from "react-feather";

export default function Card({src, title, description, isProject, itens, link}) {
 return (
   <div className={isProject? 'card-main-project': "card-main"} style={{position: isProject? 'relative': ''}}>
        <img className={isProject? 'card-image-project': "card-image"} src={src}></img>
        <div style={{display: 'flex', flexDirection:'column', justifyContent: 'center', textAlign: 'center', gap:'10px'}}>
        <span className={isProject ? 'card-project-title':"card-title"}>{title}</span>
        <div className="row-card" style={{display: isProject?'none': ''}}></div>

        {description &&(
            <p className="card-description">{description}</p>
        )}
        {itens &&(
            <div className="card-tech-project">
                <strong>Tecnologias: </strong>
                <span style={{color: '#FF8D00' }}>{itens}</span>
            </div>
             
        )}
        {link &&(
            <Link className='btn-card-project' to={link}><CiLink size={25} color="black"/> Confira</Link>
        )}
        </div>
   </div>
 );
}