import { useState, useEffect } from 'react';

function useMediaQuery(maxWidth) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= maxWidth);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= maxWidth);
    };

    // Adiciona um listener para o evento de redimensionamento
    window.addEventListener('resize', handleResize);

    // Remove o listener ao desmontar o componente
    return () => window.removeEventListener('resize', handleResize);
  }, [maxWidth]);

  return isMobile;
}

export default useMediaQuery;
