import Card from '../../components/Card';
import './projects.css'
import PersonalProjects from '../../utils/projects.json'
import { useEffect, useState } from 'react';
export default function Projects() {

const[projects, setProjects] = useState([])

  useEffect(()=>{
    setProjects(PersonalProjects);
  })
  return (
    <div style={{minHeight: '100vh'}}>
        <section className='content-project'>
        <div className="title">
                    <h2>Meus <span style={{ color: "#FF8D00" }}>Projetos</span></h2>
                    <div className="row"></div>
                </div>

                <div className='projects'>
                  {projects.map((project)=>(
                    <Card key={project.name} src={project.urlImage} isProject={true} title={project.name} description={project.description} itens={project.tecnologies} link={project.link}/>
                  ))}
                </div>
        </section>
    </div>  

  );
}