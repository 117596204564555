
import { BrowserRouter } from 'react-router-dom';
import RoutesApp from './routes';
import './index.css';
import Footer from './components/Footer';
import Header from './components/Header';

import { register } from 'swiper/element-bundle';



import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { Toaster } from 'react-hot-toast';

register();

function App() {
  return (
    <BrowserRouter>
    <Toaster
  position="top-center"
  reverseOrder={false}
/>
      <Header/>
      <RoutesApp/>
      <Footer/>
    </BrowserRouter>
    
  )}


export default App;
