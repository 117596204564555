import './home.css';
import icon from '../../assets/icon-img.jpeg';
import Card from '../../components/Card';
import { useEffect, useState } from 'react';
import tecnologies from '../../utils/tecnologies.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import { Autoplay } from 'swiper/modules';
import { Link } from 'react-router-dom';

export default function Home() {
    const [tech, setTech] = useState([]);

    useEffect(() => {
        setTech(tecnologies);
    }, []);

    return (
        <div>
            <main>
                <div className='content-home-1'>
                    <span>Olá, eu sou</span>
                    <h1>Alef Sousa Aguiar Daniel</h1>
                    <span>Desenvolvedor Fullstack</span>
                    <Link className='link' target='_blank' to="https://wa.me/5511986337043?text=Olá%20fiquei%20interessado%20em%20seus%20serviços,%20podemos%20conversar?" >Vamos conversar</Link>
                </div>
                <img className='icon' alt='Imagem de perfil' src={icon} />
            </main>
            <section className='my-stacks'>
                <div className="title-home">
                    <h2>Tecnologias & <span style={{ color: "#FF8D00" }}>Habilidades</span></h2>
                    <div className="row-home"></div>
                </div>
                <div className='content-stack'>
                    {tech.map((tecnologia) => (
                        <Card key={tecnologia.Nome} src={tecnologia.LogoURL} title={tecnologia.Nome} />
                    ))}
                </div>
                <div className='content-stack-swiper'>
                <Swiper
                    slidesPerView={3}
                    modules={[Autoplay]}
                    loop={true}
                    autoplay={{
                        delay: 2000, 
                        disableOnInteraction: false,
                    }}
                >
                    {tech.map((tecnologia) => (
                        <SwiperSlide key={tecnologia.name}>
                            <Card src={tecnologia.LogoURL} title={tecnologia.Nome} />
                        </SwiperSlide>
                    ))}

                </Swiper>
                </div>
               
            </section>
        </div>
    );
}
