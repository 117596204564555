import Input from '../../components/Input';
import { useForm } from "react-hook-form";
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import './contact.css';
import emailJs from '@emailjs/browser'
import toast from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ReactLoading from 'react-loading';
import useMediaQuery from '../../utils/useMediaQuery';
const schema = z.object({
  name: z.string().nonempty("O nome é obrigatório"),
  email: z.string().nonempty("O e-mail é obrigatório").email("E-mail inválido"),
  phone: z.string().min(11, "O telefone é obrigatório").max(12).regex(/^(\d{11})$/, "Número do telefone inválido."),
  subject: z.string().nonempty("O Assunto é obrigatório"),
  message: z.string().nonempty("A mensagem é obrigatória"),
});

export default function Contact() {





  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: zodResolver(schema),
    mode: 'onChange',
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const isMobile = useMediaQuery(938);






  async function onSubmit(data) {
    setLoading(true)
    const templateParams={
        from_name: data.name,
        subject: data.subject,
        message: data.message,
        email: data.email,
        phone: data.phone
    }

    
    await emailJs.send('service_6jk2odi', 'template_sl1s6te',templateParams, 'bEOeYPwnsUIlH7PmY')
    .then((response)=>{
        toast.success('Mensagem enviada')
        reset();
        setLoading(false)
        navigate('/')
    }, (errors)=>{
        toast.error('Não foi possivel enviar a mensagem, tente novamente.')
    })
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height:'100vh' }}>
      <section className='content-contact-page'>
        <div className="title">
          <h2>Fale <span style={{ color: "#FF8D00" }}>Comigo</span></h2>
          <div className="row"></div>
        </div>

        <span style={{ marginTop: '25px', color: 'white', fontSize: isMobile? '11px':'13px', padding: isMobile?'10px': '', textAlign:'center'}}>
          "Resta alguma dúvida? Preencha os campos abaixo com os seguintes dados que em breve entraremos em contato."
        </span>

        <form className='forms-contact' onSubmit={handleSubmit(onSubmit)}>
          <div style={{ width: '100%', display: 'flex', flexDirection: isMobile ? 'column':'row', justifyContent: 'center', alignItems: 'center', gap: '15px' }}>
            <div style={{ width: '100%', marginBottom: '0.75rem' }}>
              <p style={{ marginBottom: '0.5rem', fontWeight: '500', color: 'white' }}>Seu Nome (*)</p>
              <Input type="text" name="name" register={register} placeholder='Digite seu nome...' error={errors.name?.message}></Input>
            </div>
            <div style={{ width: '100%', marginBottom: '0.75rem', color: 'white' }}>
              <p style={{ marginBottom: '0.5rem', fontWeight: '500' }}>E-mail (*)</p>
              <Input placeholder='Digite seu e-mail...' register={register} name="email" error={errors.email?.message}></Input>
            </div>
          </div>

          <div style={{ width: '100%', display: 'flex', flexDirection: isMobile ? 'column':'row', justifyContent: 'center', alignItems: 'center', gap: '15px' }}>
            <div style={{ width: '100%', marginBottom: '0.75rem' }}>
              <p style={{ marginBottom: '0.5rem', fontWeight: '500', color: 'white' }}>Telefone (*)</p>
              <Input type="text" name="phone" register={register} placeholder='Digite seu telefone...' error={errors.phone?.message}></Input>
            </div>
            <div style={{ width: '100%', marginBottom: '0.75rem' }}>
              <p style={{ marginBottom: '0.5rem', fontWeight: '500', color: 'white' }}>Assunto (*)</p>
              <Input placeholder='Digite o assunto...' register={register} name="subject" error={errors.subject?.message}></Input>
            </div>
          </div>

          <div style={{ width: '100%', marginBottom: '0.75rem', display: 'flex', flexDirection: 'column' }}>
            <p style={{ marginBottom: '0.5rem', fontWeight: '500', color: 'white' }}>Mensagem (*)</p>
            <textarea className='message-input' {...register('message')} placeholder='Digite sua mensagem...'></textarea>
            {errors.message && <p style={{ marginBottom: '0.25rem', color: '#ef4444' }}>{errors.message.message}</p>}
          </div>
          <button type='submit' className='btn-send-contact'>
            
            {loading ?(
                <ReactLoading type='bars' color='white' width={25} height={20}></ReactLoading>
            ): 'Enviar'}
            
            
            
            
            
            </button>
        </form>
      </section>
    </div>
  );
}
