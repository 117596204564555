import './input.css'

export default function Input({type, name, placeholder, register, error,  rules }) {
 return (
   <div>
        <input className="inputs"
            id={name}
            type={type}
            placeholder={placeholder}
            {...register(name, rules)}
        >
        </input>
        {error && <p className="error-input">{error}</p>}
   </div>
 );
}